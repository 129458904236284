.current-step-indicator {
	@apply flex w-full justify-center items-center -mt-[6.2rem] mb-12 gap-[80px];
	transform: translateY(-50%);

	&__step {
		@apply relative text-2xl text-center;
		width: 100px;

		&:not(:last-child)::after {
			content: "";
			@apply block absolute top-[68px] w-[80px] -left-[80px] h-1.5 bg-blue-500 rounded-xl;
		}

		&__number {
			@apply w-16 h-16 mx-auto mt-2 rounded-full flex justify-center items-center bg-white shadow-sm;
			@apply text-blue-200 font-bold;

			@at-root .current-step-indicator__step--current & {
				@apply text-blue-500;
			}

			@at-root .current-step-indicator__step--done & {
				@apply text-blue-600;
			}
		}
	}
}
