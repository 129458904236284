/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="radio"],
input[type="checkbox"] {
    display: none;
}

input[type="radio"] ~ span,
input[type="checkbox"] ~ span {
    display: block;
}

input[type="radio"] ~ span::before,
input[type="checkbox"] ~ span::before {
    content: '';
    display: block;
    float: right;
    width: 15px;
    height: 15px;
    border: 1px solid rgb(59 130 246);
    border-radius: 50%;
    margin-left: 6px;
    margin-top: 5px;
}

input[type="radio"]:checked ~ span::before,
input[type="checkbox"]:checked ~ span::before {
    background-color: rgb(59 130 246);
}